import splashTexts from "../splashTexts.json";

export const getSplashTexts = () => {
  for (let i = splashTexts.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = splashTexts[i];
    splashTexts[i] = splashTexts[j];
    splashTexts[j] = temp;
  }

  return splashTexts.reduce<(string | number)[]>((list, elem, i) => {
    list.push(elem);
    if ((i + 1) % 2 === 1) list.push(2000);
    return list;
  }, []);
};
