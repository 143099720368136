import { HTMLMotionProps, motion } from "framer-motion";
import React from "react";
import styled from "styled-components";

const Container = styled(motion.button)<{
  size: ButtonSize;
  disabled?: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.background};
  min-width: 300px;
  padding: ${({ theme, size }) =>
    size === "lg" ? theme.spacing.lg : theme.spacing.md};
  font-size: ${({ theme, size }) =>
    size === "lg" ? theme.fontSize.lg : theme.fontSize.md};
  //box-shadow: ${({ theme }) => theme.shadows.regular};
  color: ${({ theme }) => theme.colors.primary};
  outline: none;
  border: 4px solid ${({ theme }) => theme.colors.primary};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  font-family: "Syne Mono", "Courier New", Courier, monospace;
  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.colors.primary : theme.colors.primaryDark};
    color: ${({ theme }) => theme.colors.background};
  }
  ${({ disabled }) => disabled && "opacity: 0.7;"}
`;

export type ButtonSize = "sm" | "md" | "lg";

type ButtonProps = {
  size?: ButtonSize;
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
  HTMLMotionProps<"button">;

const Button = ({ size = "md", ...props }: ButtonProps) => {
  return (
    <Container
      size={size}
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      // transition={{ duration: 0.5, type: "spring", bounce: 0.4, mass: 1.5 }}
      {...props}
    />
  );
};

export default Button;
