import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/Button";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  color: ${({ theme }) => theme.colors.primary};
  padding: ${({ theme }) => theme.spacing.xl} 0;
`;

const Title = styled.h1`
  text-transform: uppercase;
  text-align: center;
`;

const Hr = styled.div`
  margin: auto;
  width: 80%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.primary};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

const OutsideLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  &:hover {
    color: ${({ theme }) => theme.colors.primaryDark};
  }
`;

const About = () => {
  return (
    <Container>
      <Title>About</Title>
      <Hr />
      <p>
        This game was developed during{" "}
        <OutsideLink href="https://auhack.org/" target="__blank">
          AUHack 2023
        </OutsideLink>{" "}
        as a proof of concept for using text-generating AI(s) to create video
        games.
      </p>
      <p>
        In this demo, you are able to play through a story we wrote, with
        characters, interactions and quests generated by AI, as well as all the
        images and background music.
      </p>
      <p>
        The most technically challenging part was forcing the AI to be
        consistent. We achieved this by a novel approach, where we restricted
        ChatGPT to respond in a certain structure. We generate the content of
        the game top-down, meaning the only input required is a sentence of
        description. We use that to generate the locations, then the characters,
        etc., while always using the previous response as the ground truth.
      </p>
      <p>
        The current state and speed of the GPT API does not allow us to generate
        content in real time. In order to circumvent this, we pre-generate all
        text, including dialog trees (which for the sake of this demo, are not
        very broad).
      </p>
      <p>
        We used{" "}
        <OutsideLink href="https://chat.openai.com" target="__blank">
          ChatGPT 3.5
        </OutsideLink>{" "}
        for the texts,{" "}
        <OutsideLink
          href="https://www.midjourney.com/home/?callbackUrl=/app/"
          target="__blank"
        >
          Midjourney
        </OutsideLink>{" "}
        for images and{" "}
        <OutsideLink href="http://soundful.com/" target="__blank">
          Soundful Music
        </OutsideLink>{" "}
        for the background music. The background image on the home page was
        generated by Jason M. Allen using Midjourney, which won the 2022
        Colorado State Fair's art competition, sparking debate on the ethics of
        AI-generated art.{" "}
        <OutsideLink
          href="https://www.nytimes.com/2022/09/02/technology/ai-artificial-intelligence-artists.html"
          target="__blank"
        >
          Read more here.
        </OutsideLink>
      </p>
      <p>We hope you enjoy the game!</p>
      <p>
        RPGPT was created by:{" "}
        <b>
          Adam Husted Kjelstrøm, Anna Győrffy, Jędrzej Głowaczewski, Miłosz
          Głowaczewski
        </b>
      </p>
      <p>
        You can get in touch with us in email at <b>contact.rpgpt</b> (a gmail
        address).
      </p>
      <Link to="/" style={{ margin: "auto", marginTop: "1rem" }}>
        <Button>Back</Button>
      </Link>
    </Container>
  );
};

export default About;
