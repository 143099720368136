import { useEffect, useMemo } from "react";
import styled from "styled-components";
import Button from "../../components/Button";
import { useStore } from "../../store";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.xl};
  height: calc(100% - 8rem);
  justify-content: space-between;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  min-height: 0;
`;

const Title = styled.h1`
  text-transform: uppercase;
`;

const Hr = styled.div`
  width: 80%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  flex: 1 0 auto;
`;

const DescriptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  justify-content: start;
`;

const DescriptionsOuterContainer = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  flex-direction: column-reverse;
  overflow: auto;
`;

const Description = styled.div`
  background-color: transparent;
  padding: 0.3rem;
`;

const PlayerText = styled.div`
  background-color: #0000004d;
  padding: 0.6rem 1rem;
  margin: 0.7rem;
  margin-left: 4rem;
`;

const Image = styled.img`
  width: 30%;
  border: 1px solid ${({ theme }) => theme.colors.primary};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  width: 100%;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: ${({ theme }) => theme.spacing.lg};
  width: 100%;
`;

type MainDialogProps = {};

const MainDialog = ({}: MainDialogProps) => {
  const {
    currentInteractionId,
    interactions,
    initInteractions,
    nextInteraction,
    descriptions,
    completedLocationIds,
  } = useStore();

  useEffect(() => {
    initInteractions();
  }, []);

  const currentInteraction = useMemo(() => {
    return interactions.find(
      (interaction) => interaction.id === currentInteractionId
    );
  }, [currentInteractionId, interactions]);

  return (
    <Container>
      <ContentContainer>
        <HeaderContainer>
          {currentInteraction?.imageName && (
            <Image src={`${currentInteraction?.imageName}.png`} />
          )}
          <ColumnContainer>
            <Title>{currentInteraction?.name}</Title>
            <Hr />
          </ColumnContainer>
        </HeaderContainer>
        <DescriptionsOuterContainer>
          <DescriptionsContainer>
            {descriptions.map((description) => {
              if (description.type === "player") {
                return (
                  <PlayerText key={description.description}>
                    {description.description}
                  </PlayerText>
                );
              } else {
                return (
                  <Description key={description.description}>
                    {description.description}
                  </Description>
                );
              }
            })}
          </DescriptionsContainer>
        </DescriptionsOuterContainer>
      </ContentContainer>
      <OptionsContainer>
        {currentInteraction?.actions
          .filter((action, index) => {
            if (!action.name.includes("Travel to")) {
              return true;
            } else {
              if (index !== currentInteraction?.actions.length - 1) {
                return true;
              } else {
                return completedLocationIds.length === 2;
              }
            }
          })
          .map((action) => (
            <Button key={action.name} onClick={() => nextInteraction(action)}>
              {completedLocationIds.includes(action.nextInteraction) && "✓ "}
              {action.name}
            </Button>
          ))}
      </OptionsContainer>
    </Container>
  );
};

export default MainDialog;
