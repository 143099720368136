import React from "react";
import styled from "styled-components";

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import Home from "./pages/Home";
import Game from "./pages/game";
import About from "./pages/About";

const Page = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "game",
    element: <Game />,
  },
  {
    path: "about",
    element: <About />,
  },
]);

function App() {
  return (
    <Page>
      <RouterProvider router={router} />
    </Page>
  );
}

export default App;
