import gameContent from "../assets/content/game_content4.json";
import { Action, Interaction } from "../store/dataSlice";
import { v4 } from "uuid";

export function loadContent(): Interaction[] {
  const mainLocationId = v4();
  const endLocationId = "endLocationId";

  const locations: Interaction[][] = gameContent.locations.map((location) =>
    parseLocation(location, mainLocationId, gameContent.beginning.name)
  );

  const startLocation: Interaction = {
    id: mainLocationId,
    name: gameContent.beginning.name,
    locationImageName: gameContent.beginning.name,
    actions: interactionsListToActions(locations, "Travel to "),
    descriptions: gameContent.beginning.introduction.map((description) => ({
      description: description,
      type: "description",
    })),
    type: "mainLocation",
  };

  const endLocation: Interaction = {
    id: endLocationId,
    name: gameContent.ending.name,
    locationImageName: gameContent.ending.name,
    actions: [],
    descriptions: gameContent.ending.description.map((description) => ({
      description: description,
      type: "description",
    })),
    type: "endLocation",
  };

  return [
    startLocation,
    ...locations.flatMap((locations) => locations),
    endLocation,
  ];
}

function parseLocation(
  location: any,
  parentLocationId: string,
  parentLocationName: string
): Interaction[] {
  const locationId = v4();
  const npcInteractions: Interaction[][] = location.npc_list.map((npc: any) =>
    parseNpcInteractions(npc, locationId, location.name)
  );

  return [
    {
      id: locationId,
      parentLocationId: parentLocationId,
      locationImageName: location.name,
      name: location.name,
      quest: location.quest,
      descriptions: [
        {
          description: location.description,
          type: "description",
        },
      ],
      type: "subLocation",
      actions: [
        ...interactionsListToActions(npcInteractions, "Talk with "),
        createLeaveLocation(parentLocationId),
      ],
    },
    ...npcInteractions.flatMap((interactions) => interactions),
  ];
}

function parseNpcInteractions(
  npc: any,
  parentLocationId: string,
  parentLocationName: string
): Interaction[] {
  const npcInteractionId = v4();
  const actions: Interaction[][] = npc.actions.map((action: any) =>
    parseAction(
      action,
      parentLocationId,
      npcInteractionId,
      parentLocationName,
      npc.name
    )
  );

  return [
    {
      id: npcInteractionId,
      parentLocationId: parentLocationId,
      locationImageName: parentLocationName,
      name: npc.name,
      imageName: npc.name,
      descriptions: [
        {
          description: npc.description,
          type: "description",
        },
      ],
      type: "interaction",
      actions: interactionsListToActions(actions, ""),
    },
    ...actions.flatMap((actions) => actions),
  ];
}

function parseAction(
  action: any,
  parentLocationId: string,
  npcInteractionId: string,
  parentLocationName: string,
  imageName: string
): Interaction[] {
  const subActions: Interaction[][] =
    action?.next_action?.map((subAction: any) =>
      parseAction(
        subAction,
        parentLocationId,
        npcInteractionId,
        parentLocationName,
        imageName
      )
    ) ?? [];

  return [
    {
      id: v4(),
      imageName,
      parentLocationId: parentLocationId,
      locationImageName: parentLocationName,
      name: action.description_on_button,
      descriptions: [
        {
          description: `${action.player_action}`,
          type: "player",
        },
        {
          description: `${action.outcome_action}`,
          type: "npc",
        },
      ],
      type: "dialog",
      actions:
        subActions.length > 0
          ? interactionsListToActions(subActions, "")
          : [createEndNpcInteraction(parentLocationId, npcInteractionId)],
    },
    ...subActions.flatMap((actions) => actions),
  ];
}

function createEndNpcInteraction(
  parentInteractionId: string,
  npcInteractionId: string
): Action {
  return {
    name: "End the conversation",
    nextInteraction: parentInteractionId,
    npcInteractionId: npcInteractionId,
  };
}

function createLeaveLocation(parentInteractionId: string): Action {
  return {
    name: "Leave the location",
    nextInteraction: parentInteractionId,
  };
}

function interactionsListToActions(
  interactions: Interaction[][],
  namePrefix: string
): Action[] {
  return interactionsToActions(
    interactions.map((subInteractions) => subInteractions[0]),
    namePrefix
  );
}

function interactionsToActions(
  interactions: Interaction[],
  namePrefix: string
): Action[] {
  return interactions?.map((subInteraction) => ({
    name: `${namePrefix}${subInteraction.name}`,
    nextInteraction: subInteraction.id,
  }));
}
