import { DefaultTheme } from "styled-components";

const palette = {
  black900: "#000",
  black700: "#1A1F22",
  black600: "#1E1E1E",
  white900: "#fff",
  yellow500: "#FFDAAF",
  yellow600: "#ffe3c3",
};

const spacing = {
  xs: "0.25rem",
  sm: "0.5rem",
  md: "1rem",
  lg: "2rem",
  xl: "4rem",
};

const fontSize = {
  sm: "0.75rem",
  md: "1rem",
  lg: "1.5rem",
  xl: "2rem",
};

export const defaultTheme: DefaultTheme = {
  colors: {
    background: palette.black600,
    text: {
      regular: palette.yellow500,
      contrast: palette.white900,
    },
    primary: palette.yellow500,
    primaryDark: palette.yellow600,
    secondary: palette.black700,
  },
  shadows: {
    regular: `0 0 10px ${palette.black900}`,
  },
  spacing,
  fontSize,
};
