import { create } from "zustand";
import { persist } from "zustand/middleware";
import { createDataSlice, DataSlice } from "./dataSlice";

type State = DataSlice; // & OtherSlice & AnotherSlice;

export const useStore = create<State>()(
  persist(
    (set, get) => ({
      ...createDataSlice(set, get),
    }),
    {
      name: "rpgpt-store",
    }
  )
);
