import { StoreSlice } from "./store";
import { loadContent } from "../services/content_parser";

export interface DataSlice {
  currentInteractionId: string;
  interactions: Interaction[];
  descriptions: Description[];
  currentQuest?: Quest;

  completedLocationIds: string[];

  audioOn: boolean;
  toggleAudio: () => void;

  initInteractions: () => void;

  nextInteraction: (action: Action) => void;
}

export const createDataSlice: StoreSlice<DataSlice> = (set, get) => ({
  currentInteractionId: "",
  interactions: [],
  descriptions: [],
  completedLocationIds: [],
  initInteractions: async () => {
    const interactions = loadContent();

    set(() => ({
      currentInteractionId: interactions[0].id,
      interactions: interactions,
      descriptions: interactions[0].descriptions,
      currentQuest: undefined,
      completedLocationIds: [],
    }));
  },
  nextInteraction: async (selectedAction) => {
    const interactions: Interaction[] = get().interactions;
    const quest: Quest = get().currentQuest;
    let nextInteraction = interactions.find(
      (interaction) => interaction.id === selectedAction.nextInteraction
    )!;

    if (nextInteraction.type === "subLocation") {
      set(() => ({
        currentQuest: nextInteraction.quest,
      }));
    }
    if (
      nextInteraction.type === "mainLocation" ||
      nextInteraction.type === "endLocation"
    ) {
      set(() => ({
        currentQuest: undefined,
      }));
    }

    if (selectedAction.npcInteractionId !== undefined) {
      const npcInteraction = interactions.find(
        (interaction) => interaction.id === selectedAction.npcInteractionId
      )!;
      console.log(
        `NPC interaction ${npcInteraction.name}, npc to interact ${quest?.npc_to_interact}`,
        quest
      );
      if (npcInteraction.name === quest?.npc_to_interact) {
        const completedLocationId = interactions.find(
          (interaction) => interaction.quest?.description === quest.description
        )!.id;
        if (!get().completedLocationIds.includes(completedLocationId)) {
          set(() => ({
            currentQuest: {
              ...quest,
              completed: true,
            },
            completedLocationIds: [
              ...get().completedLocationIds,
              completedLocationId,
            ],
          }));
        }
      }
    }

    if (
      nextInteraction.type === "subLocation" &&
      get().completedLocationIds.length === 3
    ) {
      nextInteraction = interactions.find(
        (interaction) => interaction.id === "endLocationId"
      )!;
    }

    const descriptions: Description[] = get().descriptions;

    const keepDescriptions = nextInteraction.type === "dialog";

    set(() => ({
      currentInteractionId: nextInteraction.id,
      descriptions: [
        ...(keepDescriptions ? descriptions : []),
        ...nextInteraction.descriptions,
      ],
    }));
  },
  audioOn: true,
  toggleAudio: () => {
    const audioOn = get().audioOn;
    set(() => ({
      audioOn: !audioOn,
    }));
  },
});

export interface Interaction {
  id: string;
  name: string;
  parentLocationId?: string;
  descriptions: Description[];
  actions: Action[];
  imageName?: string;
  locationImageName: string;
  quest?: Quest;
  type:
    | "mainLocation"
    | "subLocation"
    | "interaction"
    | "dialog"
    | "endLocation";

  // npcs: Npc[];
  // quests: Quest[];
}

export interface Description {
  description: string;
  type: "player" | "npc" | "description";
}

export interface Npc {
  name: string;
  description: string;
  actions: Action[];
}

export interface Action {
  name: string;
  nextInteraction: string;

  npcInteractionId?: string;
}

export interface Quest {
  description: string;
  npc_to_interact: string;
  requirements: string;
  reward: string;
  completed?: boolean;
}

export interface ChatResponse {
  id: string;
  object: string;
  created: number;
  choices: Choice[];
  usage: Usage;
}

export interface Choice {
  index: number;
  message: Message;
  finish_reason: string;
}

export interface Message {
  role: string;
  content: string;
}

export interface Usage {
  prompt_tokens: number;
  completion_tokens: number;
  total_tokens: number;
}
