import { motion, useAnimate } from "framer-motion";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import GeneratedImage from "../../assets/VillageOnFire.png";
import { useStore } from "../../store";
import { Interaction } from "../../store/dataSlice";
import MainDialog from "./MainDialog";
import AmbientAudio from "../../assets/ambient.mp3";

const Container = styled(motion.div)`
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  color: ${({ theme }) => theme.colors.text.regular};
  display: flex;
  flex-direction: row;
  position: relative;
`;

const Column = styled.div<{ isWide?: boolean }>`
  flex: ${({ isWide }) => (isWide ? 2 : 1)};
  border-right: 1px solid ${({ theme }) => theme.colors.primary};
  min-height: 100vh;
  position: relative;
`;

const GeneratedImageContainer = styled.div`
  //background-image: url(${GeneratedImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
`;

const ExitButton = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  padding: ${({ theme }) => theme.spacing.sm};
  position: absolute;
  top: ${({ theme }) => theme.spacing.sm};
  left: ${({ theme }) => theme.spacing.sm};
  font-size: 30px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.regular};
  &:hover {
    opacity: 1;
    background-color: red;
  }
`;

const MainContainer = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
`;

const QuestContainer = styled.div`
  position: fixed;
  width: 30vw;
  top: ${({ theme }) => theme.spacing.md};
  left: 1vw;
  padding: ${({ theme }) => theme.spacing.sm};
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid ${({ theme }) => theme.colors.primary};
  margin-right: 1vw;
  font-size: 15px;
`;

const MuteButton = styled.button`
  outline: none;
  border: none;
  position: fixed;
  bottom: ${({ theme }) => theme.spacing.md};
  left: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => theme.spacing.md};
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 100%;
  cursor: pointer;
`;

const AudioImage = styled.img`
  width: 25px;
  height: 25px;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`;

const Game = () => {
  const [scope, animate] = useAnimate();
  const audioRef = useRef(new Audio(AmbientAudio));

  const {
    currentInteractionId,
    interactions,
    currentQuest,
    audioOn,
    toggleAudio,
  } = useStore();
  const [imageUrl, setImageUrl] = useState("");
  const [prevInteraction, setPrevInteraction] = useState<Interaction>();

  const currentInteraction = useMemo(() => {
    return interactions.find((i) => i.id === currentInteractionId);
  }, [currentInteractionId, interactions]);

  useEffect(() => {
    const animation = async () => {
      await animate(scope.current, {
        scale: [0.8, 1],
        opacity: [0, 1],
        duration: 0.5,
      });
    };

    if (
      ["mainLocation", "subLocation"].includes(
        currentInteraction?.type ?? ""
      ) ||
      currentInteraction?.type === "interaction"
    ) {
      animation();
    }
  }, [currentInteraction]);

  useEffect(() => {
    if (prevInteraction?.id !== currentInteraction?.id) {
      setPrevInteraction(currentInteraction);
    }
  }, [currentInteraction, prevInteraction?.id]);

  useEffect(() => {
    const load = async () => {
      const interaction = interactions.find(
        (i) => i.id === currentInteractionId
      );
      if (!interaction || !interaction.locationImageName) {
        setImageUrl(GeneratedImage);
        return;
      }
      const image = `${interaction?.locationImageName}.png`;
      console.log(image);
      setImageUrl(image);
    };

    load();
  }, [currentInteractionId, interactions]);

  /*const image = useMemo(async () => {
    const interaction = interactions.find((i) => i.id === currentInteractionId);
    if (!interaction) return GeneratedImage;
    return await import(
      `../../assets/content/${interaction?.locationImageName}.png`
    );
    //return require(`../../assets/content/${interaction?.locationImageName}.png`);
  }, []);*/

  useEffect(() => {
    audioRef.current.loop = true;
    audioRef.current.volume = 0.5;
    if (audioOn) audioRef.current.play();
    else audioRef.current.pause();
  }, [audioOn]);

  return (
    <Container
      key="GamePage"
      exit={{ x: -300, opacity: 0 }}
      initial={{ x: 300, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
    >
      <Link to="/">
        <ExitButton>X</ExitButton>
      </Link>
      <MuteButton onClick={toggleAudio}>
        <AudioImage
          src={audioOn ? "audio.png" : "mute.png"}
          alt={audioOn ? "mute" : "audio on"}
        />
      </MuteButton>
      <Column>
        {currentQuest && (
          <QuestContainer>
            {currentQuest.description}
            <br />
            <br />
            <b>{currentQuest.completed && "Completed!"}</b>
          </QuestContainer>
        )}
        <GeneratedImageContainer
          style={{ backgroundImage: `url("${imageUrl}")` }}
        />
      </Column>

      <Column isWide style={{ height: "100%" }}>
        <MainContainer ref={scope}>
          <MainDialog />
        </MainContainer>
      </Column>

      {/*       <Column>
        <SideBar />
      </Column> */}
    </Container>
  );
};

export default Game;
