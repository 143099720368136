import React from "react";
import { Link } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import DefaultBackgroundImage from "../assets/default.png";
import AUHack from "../assets/auhack.png";
import Button from "../components/Button";
import { TypeAnimation } from "react-type-animation";
import { motion } from "framer-motion";
import { getSplashTexts } from "../services/splashTextSequencer";

const Container = styled(motion.div)`
  background-image: url(${DefaultBackgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 10rem;
  color: ${({ theme }) => theme.colors.text.contrast};
  text-shadow: 0 0 10px ${({ theme }) => theme.colors.text.regular};
  margin-bottom: 0;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: ${({ theme }) => theme.spacing.sm};
  }
`;

const HackContainer = styled.a`
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.8);
  color: ${({ theme }) => theme.colors.primary};
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${({ theme }) => theme.spacing.lg};
  left: ${({ theme }) => theme.spacing.lg};
`;

const HackLogo = styled.img`
  width: 150px;
`;

const sequence = getSplashTexts();

const Home = () => {
  const theme = useTheme();
  return (
    <Container
      key="HomePage"
      exit={{ x: 300, opacity: 0 }}
      initial={{ x: -300, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
    >
      <HackContainer href="https://auhack.org/" target="__blank">
        <HackLogo src={AUHack} />
        <span>Best Hack 2023</span>
      </HackContainer>
      <Title>RPGPT</Title>
      <TypeAnimation
        sequence={sequence}
        wrapper="div"
        cursor={true}
        repeat={Infinity}
        style={{
          fontSize: theme.fontSize.xl,
          color: theme.colors.text.contrast,
          display: "inline-block",
          textShadow: theme.shadows.regular,
          marginBottom: theme.spacing.md,
          padding: 4,
          backgroundColor: theme.colors.secondary,
        }}
      />
      <LinkContainer>
        <Link to="game">
          <Button>Start Game</Button>
        </Link>
        <Link to="about">
          <Button>About</Button>
        </Link>
      </LinkContainer>
    </Container>
  );
};

export default Home;
